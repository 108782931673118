/* Base styles for the card */
.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  
  /* Header styling */
  .card-header {
    background-color: #f5f5f5;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card-header h1,
  .card-header h2,
  .card-header h3,
  .card-header h4,
  .card-header h5,
  .card-header h6 {
    margin: 0;
  }
  
  /* Content area styling */
  .card-content {
    flex: 1;
    padding: 16px;
    overflow: auto;
  }
  
  /* Footer styling */
  .card-footer {
    background-color: #f5f5f5;
    padding: 16px;
  }
  
  /* Animated footer */
  .animated-footer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .animated-footer.visible {
    max-height: 200px; /* Adjust based on content */
  }
  
  /* Toggle footer button */
  .toggle-footer-btn {
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
  }
  