/* print.css */

@media print {
  body {
    -webkit-print-color-adjust: exact; /* Adjust colors for print */
    font-size: 1.2rem; /* Increase font size for better readability */
  }

  .print-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .print-logo {
    height: 8rem; /* Adjust logo size */
  }

  .print-image {
    height: 800px; /* Adjust image size */
  }

  .print-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .print-details > div {
    width: 48%; /* Adjust detail box width for two columns */
    margin-bottom: 20px;
  }

  .print-contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .print-contact > div {
    width: 100%; /* Adjust contact box width for two columns */
    margin-bottom: 20px;
  }

  .MuiTypography-root {
    font-size: 1.2rem !important; /* Increase font size for material UI Typography */
  }
}
