iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

body, html {
    margin: 0;
    padding: 0;
  }

  @font-face {
    font-family: 'Inter'; /* Give the font a name */
    src: url('./fonts/Inter-VariableFont_slnt-wght.ttf') format('truetype'); /* Point to the font file in the public/fonts/ directory */
  }